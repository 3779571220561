// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS">
            <div className="content">
                <h2>Facturar pagos <br /><span className="small">(instrucciones)</span></h2>
                <p>
                    En esta sección detallamos brevemente las instrucciones para facturar pagos diversos de forma que sepas como estructurar este flujo. <br /> Si deseas ir directamente al complemento de pagos, entra <Link to="./cfdi-complemento-pago">aquí</Link>.
                </p>
                <p>
                    Cuando un cliente requiere facturar pagos, u operar con pagos diversos, es importante saber el proceso que deben seguir <b>ya que es común que los clientes no sepan como hacerlo, pues el SAT no comunica bien la diferencia entre facturas PUE y PPD (Pago parcialidades diferidas).</b> <br />
                    Los flujos comúnes cuando un cliente habla de Facturar pagos son los siguientes:
                </p>
                <ul>
                    <li>
                        <p>
                            <b>Cliente recibe un anticipo y se liquidará después: </b> Este proceso <span className="error">no requiere complemento de pago, </span> y se realiza al emitir dos facturas y una nota de crédito.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Cliente debe emitir la factura y recibirá el pago después: </b> Este proceso puede hacer uso del complemento de pago, se emite una factura en PPD y un complemento de pago.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Cliente vende un producto o servicio en mensualidades: </b>
                        </p>
                    </li>
                </ul>
                <p>
                    Una nota importante de los complementos de pago, cuando se usan. <b>Se debe emitir el complemento a más tardar el dia 10 del mes siguiente al que se recibió el pago</b>.
                </p>
                <h3>Cliente recibe un anticipo y se liquidará después</h3>
                <p>
                    Consideramos el caso en que el emisor vende un producto con $350'000 de valor total, el cliente hace un pago de $10'000 como anticipo en Julio y liquida el pago a final de Agosto (los $340'000 faltantes).
                </p>
                <ol>
                    <li>
                        <p>
                            <b>El emisor creará una factura PUE con el concepto de "Pago del anticipo", usando la forma de pago adecuada (03, 04, etc.)</b> con el valor del anticipo ($10'000).
                        </p>
                    </li>
                    <li>
                        <p><b>El emisor creará una factura por el valor TOTAL de la operación cuando sea liquidado, con el metodo de pago PUE, y forma de pago adecuada.</b> No se debe considerar el anticipo en este caso, sino en el siguiente punto se corrige.</p>
                    </li>
                    <li>
                        <p><b>El emisor generará una nota de crédito con el valor del anticipo ($10'000) y la relacionará a la factura del #2, la que cuenta con el valor TOTAL.</b> Esta nota de crédito será la que ajusta a la factura del valor total, el pago del anticipo del #1.</p>
                    </li>
                </ol>
                <svg width="496px" viewBox="0 0 396 116" version="1.1">
                    <g id="PagoAnticipo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect id="Rectangle" stroke="#979797" x="0.5" y="0.5" width="66" height="86"></rect>
                        <text id="CFDI" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="20" y="48">CFDI</tspan>
                        </text>
                        <text id="Anticipo-$10’000" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="11.291" y="99">Anticipo</tspan>
                            <tspan x="12.698" y="114">$10’000</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" x="232.5" y="0.5" width="66" height="86"></rect>
                        <text id="CFDI" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="252" y="48">CFDI</tspan>
                        </text>
                        <text id="Liquidación-$350’000" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="236.0945" y="99">Liquidación</tspan>
                            <tspan x="242.285" y="114">$350’000</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" x="329.5" y="0.5" width="66" height="86"></rect>
                        <text id="Nota-Crédito" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="349.416" y="41">Nota</tspan>
                            <tspan x="343.0995" y="56">Crédito</tspan>
                        </text>
                        <text id="Por-anticipo---$10’0" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="329.027" y="99">Por anticipo</tspan>
                            <tspan x="337.223" y="114">- $10’000</tspan>
                        </text>
                        <path id="Line" d="M214,39 L223,43.5 L214,48 L214,44 L80,44 L80,43 L214,43 L214,39 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <path id="Line-2" d="M314,39 L323,43.5 L314,48 L314,44 L302,44 L302,43 L314,43 L314,39 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                    </g>
                </svg>
                <p>
                    Como puedes notar, en ningún momento se emitió un complemento de pago ni una factura en PPD. Este es el proceso estándar que los clientes deben seguir cuando el pago es un solo anticipo y una liquidación final. <br />
                    <span className="small quote">Si el proceso tendrá mas pagos que solo el anticipo y liquidación, se considera un pago a mensualidades.</span>
                </p>
                <h3>
                    Cliente debe emitir la factura y recibirá el pago después.
                </h3>
                <p>
                    Este concepto es muy importante de saber, y varios clientes se han encontrado entre un problema vendiendo negocio a negocio donde las empresas aplican políticas internas sin conocimiento de las reglas fiscales. <b>Es importante poder orientar a nuestros clientes para saber como operar estos casos </b> donde
                    el comprador no paga si "no recibe factura" y el vendedor "no emite factura si no hay pago". <br />
                </p>
                <p>
                    En el ejemplo de arriba, ambos están en lo correcto, puedes emitir una factura PUE si recibes el pago el mismo mes y también es buena práctica no emitir factura hasta que el pago llegue. <br />
                    <span className="small quote">Para asistir a nuestros clientes con esta problemática común, haremos lo siguiente.</span>
                </p>
                <ol>
                    <li>
                        <p>
                            <b>Cuando el emisor deba emitir una factura para que le liberen el pago </b>tendrá que realizar una factura con metodo de pago PPD y forma de pago 99. <b>Esta es la factura base al que se le aplicará el complemento de pago</b>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>
                                Una vez recibido el pago, se emitirá un complemento de pago por el valor total de la factura.
                            </b>
                        </p>
                    </li>
                </ol>
                <svg width="480px" viewBox="0 0 447 280" version="1.1" >
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect id="Rectangle" stroke="#979797" x="126.5" y="0.5" width="66" height="86"></rect>
                        <text id="CFDI-PPD" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="146.372" y="44">CFDI</tspan>
                            <tspan x="148.0325" y="59">PPD</tspan>
                        </text>
                        <text id="Total-de-la-venta" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="137.42" y="99">Total de</tspan>
                            <tspan x="137.2805" y="114">la venta</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" x="347.5" y="0.5" width="90" height="86"></rect>
                        <text id="Complemento-Pago" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="354.416" y="44">Complemento</tspan>
                            <tspan x="379.172" y="59">Pago</tspan>
                        </text>
                        <text id="Por-el-Total" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="377.756" y="99">Por el</tspan>
                            <tspan x="380.003" y="114">Total</tspan>
                        </text>
                        <path id="Line" d="M160,125 L160,214 L164,214 L159.5,223 L155,214 L159,214 L159,125 L160,125 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <path id="Line" d="M392.5,117 L397,126 L393,126 L393,204 L392,204 L392,126 L388,126 L392.5,117 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <path id="Line" d="M433.74888,91.2488827 L433.749999,91.7488814 L433.981,194.998 L437.980973,194.989954 L433.501119,203.999999 L428.980996,195.010088 L432.981,195.001 L432.750001,91.7511186 L432.748883,91.2511198 L433.74888,91.2488827 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <ellipse id="Oval" fill="#9C9C9C" cx="33.5" cy="36" rx="18.5" ry="19"></ellipse>
                        <path d="M33.5,88.6234801 C52.0015391,88.6234801 67,92.1658804 67,77.5195419 C67,62.8732033 52.0015391,51 33.5,51 C14.9984609,51 0,62.8732033 0,77.5195419 C0,92.1658804 14.9984609,88.6234801 33.5,88.6234801 Z" id="Oval" fill="#9C9C9C"></path>
                        <ellipse id="Oval" fill="#9C9C9C" cx="33.5" cy="207" rx="18.5" ry="19"></ellipse>
                        <path d="M33.5,259.62348 C52.0015391,259.62348 67,263.16588 67,248.519542 C67,233.873203 52.0015391,222 33.5,222 C14.9984609,222 0,233.873203 0,248.519542 C0,263.16588 14.9984609,259.62348 33.5,259.62348 Z" id="Oval" fill="#9C9C9C"></path>
                        <path id="Line-2" d="M363,222 L372,226.5 L363,231 L363,227 L159,227 L159,226 L363,226 L363,222 Z" fill="#979797" fillRule="nonzero"></path>
                        <rect id="Rectangle" stroke="#979797" x="380.5" y="212.5" width="66" height="27"></rect>
                        <text id="$" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="16" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="408.006" y="231">$</tspan>
                        </text>
                        <text id="Cliente-paga" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="16" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="386.536" y="256">Cliente</tspan>
                            <tspan x="392.136" y="276">paga</tspan>
                        </text>
                    </g>
                </svg>
                <p>
                    Si bien, se puede emitir una factura normal antes de recibir el pago, ofrecer este flujo como el principal para estos casos es muy util para evitar problemas entre políticas de pagos de varias empresas.
                </p>
                <h3>
                    Cliente vende un producto o servicio en mensualidades
                </h3>
                <p>
                    Este es el uso general del complemento de pago, las mensualidades serán los complementos de pago hasta liquidar el saldo pendiente.
                </p>
                <ol>
                    <li>
                        <p>
                            <b>Vendedor emite una factura con forma de pago 99 y metodo de pago PPD por el valor total de la factura.</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            Cada vez que reciba un pago emitirá un complemento de pago por el valor del pago asociado a el UUID de la factura PPD original.
                        </p>
                    </li>
                </ol>
                <svg width="593px" height="260px" viewBox="0 0 593 260" version="1.1">

                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <line x1="98.5" y1="226.5" x2="569.5" y2="225.5" id="Line-2" stroke="#979797" strokeLinecap="square"></line>
                        <rect id="Rectangle" stroke="#979797" x="126.5" y="0.5" width="66" height="86"></rect>
                        <text id="CFDI-PPD" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="146.372" y="44">CFDI</tspan>
                            <tspan x="148.0325" y="59">PPD</tspan>
                        </text>
                        <text id="Total-de-la-venta" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="137.42" y="99">Total de</tspan>
                            <tspan x="137.2805" y="114">la venta</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" x="276.5" y="47.5" width="53" height="39"></rect>
                        <text id="Pago" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="287.172" y="71">Pago</tspan>
                        </text>
                        <text id="Parcialidad" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="272.1975" y="99">Parcialidad</tspan>
                        </text>
                        <path id="Line" d="M160,125 L160,214 L164,214 L159.5,223 L155,214 L159,214 L159,125 L160,125 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <path id="Line" d="M302.5,102 L307,111 L303,111 L303,204 L302,204 L302,111 L298,111 L302.5,102 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <rect id="Rectangle" stroke="#979797" x="456.5" y="47.5" width="53" height="39"></rect>
                        <text id="Pago" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="467.172" y="71">Pago</tspan>
                        </text>
                        <text id="Parcialidad" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="452.1975" y="99">Parcialidad</tspan>
                        </text>
                        <path id="Line" d="M482.5,102 L487,111 L483,111 L483,204 L482,204 L482,111 L478,111 L482.5,102 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <rect id="Rectangle" stroke="#979797" x="536.5" y="47.5" width="53" height="39"></rect>
                        <text id="Pago" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="547.172" y="71">Pago</tspan>
                        </text>
                        <text id="Parcialidad" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="532.1975" y="99">Parcialidad</tspan>
                        </text>
                        <path id="Line" d="M562.5,102 L567,111 L563,111 L563,204 L562,204 L562,111 L558,111 L562.5,102 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <rect id="Rectangle" stroke="#979797" x="366.5" y="47.5" width="53" height="39"></rect>
                        <text id="Pago" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="377.172" y="71">Pago</tspan>
                        </text>
                        <text id="Parcialidad" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="12" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="362.1975" y="99">Parcialidad</tspan>
                        </text>
                        <path id="Line" d="M392.5,102 L397,111 L393,111 L393,204 L392,204 L392,111 L388,111 L392.5,102 Z" fill="#CB4B0D" fillRule="nonzero"></path>
                        <ellipse id="Oval" fill="#9C9C9C" cx="33.5" cy="36" rx="18.5" ry="19"></ellipse>
                        <path d="M33.5,88.6234801 C52.0015391,88.6234801 67,92.1658804 67,77.5195419 C67,62.8732033 52.0015391,51 33.5,51 C14.9984609,51 0,62.8732033 0,77.5195419 C0,92.1658804 14.9984609,88.6234801 33.5,88.6234801 Z" id="Oval" fill="#9C9C9C"></path>
                        <ellipse id="Oval" fill="#9C9C9C" cx="33.5" cy="207" rx="18.5" ry="19"></ellipse>
                        <path d="M33.5,259.62348 C52.0015391,259.62348 67,263.16588 67,248.519542 C67,233.873203 52.0015391,222 33.5,222 C14.9984609,222 0,233.873203 0,248.519542 C0,263.16588 14.9984609,259.62348 33.5,259.62348 Z" id="Oval" fill="#9C9C9C"></path>
                        <rect id="Rectangle" stroke="#979797" fill="#D8D8D8" x="289.5" y="211.5" width="26" height="26"></rect>
                        <text id="$" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="16" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="298.006" y="231">$</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" fill="#D8D8D8" x="379.5" y="211.5" width="26" height="26"></rect>
                        <text id="$" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="16" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="388.006" y="231">$</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" fill="#D8D8D8" x="469.5" y="211.5" width="26" height="26"></rect>
                        <text id="$" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="16" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="478.006" y="231">$</tspan>
                        </text>
                        <rect id="Rectangle" stroke="#979797" fill="#D8D8D8" x="549.5" y="211.5" width="26" height="26"></rect>
                        <text id="$" fontFamily="Quicksand-Light_Regular, Quicksand" fontSize="16" fontWeight="normal" fill="#6F6F6F">
                            <tspan x="558.006" y="231">$</tspan>
                        </text>
                    </g>
                </svg>
            </div>
            <SectionNavigate next={`/api/referencia/cfdi-complemento-pago`} previous={`/api/referencia/cfdi-find`}/>
        </div>
    </Layout >
)

export default ApiCfdiMinimo
